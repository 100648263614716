(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("aws_amplify_core"));
	else if(typeof define === 'function' && define.amd)
		define("aws_amplify_auth", ["aws_amplify_core"], factory);
	else if(typeof exports === 'object')
		exports["aws_amplify_auth"] = factory(require("aws_amplify_core"));
	else
		root["aws_amplify_auth"] = factory(root["aws_amplify_core"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__aws_amplify_core__) {
return 